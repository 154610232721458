@media screen and (max-width:450px)
{
body #div{
   padding:20px 15px 5px;
 
 }
 
  
 
 
 body .ion-text{
  margin-top:8px;
  
}
}

@media screen and (max-width:360px){
  body .div-search{
    grid-template-columns:90% 10% ;
  }
  body .instruct1{
    margin:50px 12px 3px 15px;
  }
  body .card1-heder2 #card1-heder2-2{
    padding:0;
  }
  body #card1-heder2-1{
    font-size:.8rem;
  }
  body .card1-heder2 #card1-heder2-2{
    font-size:.55rem;
  }
}


#div{
  display:flex;
  padding:25px 25px 5px;
  font-family: 'Open Sans', sans-serif;
  justify-content: space-between;
  align-items: center;

}

#icon-noti{
  --background:rgb(228, 235, 240);
  --border:none;
}
#div>div{
   font-size:1.1rem;
  font-weight:bolder;
  align-self:center;
}
#ion-item{
  --background:none!important;
}
.ion-label p{
  font-family: 'Noto Sans', sans-serif;
  font-size:.95rem;
  font-weight: bolder;
  color:rgb(36, 34, 34);
}
.ion-label>h1{
  padding:5.2px  0px;
  font-family: 'Tajawal', sans-serif;
  font-size:.9em;
  color:rgb(117, 114, 114);
  font-weight: 600;
}
.username{
  color:rgba(20, 118, 172, 0.9);
}
#img{
  border-radius:50%;
  padding: 8px;
}
.ion-button{
  display: inline-flex;
  align-items: center;
  justify-content:center;
 background: #3880ff;
border-radius: 10%;
  padding:5px 2px ;


  
}


.div-search{
 display:grid;
 padding:3px 13px 3px 10px;
  margin:0;
 grid-template-columns:92% 8% ;
}

#search-bar{
  --background:#ffffff;
  --border:none;
  --border-radius:10px;
  --box-shadow: none !important;
    
  --cancel-button-color:rgb(220, 209, 232);
 

}
#search-bar .searchbar-input-container input::placeholder {
  font-size:.85rem;
  font-family: 'Noto sans', sans-serif;
  --placeholder-font-weight:500;
  padding:0;
 --margin:0;
}
#sld{
  height:125px;
}
.slide1{
  
 --background:rgb(255, 255, 255);
 margin-left:12px;
 border-radius: 8px;
 box-shadow:  3px 3px rgba(188, 192, 188,0.5);
 }
 

#attendence{
  font-size:3.2mm;
  border-radius: 50%;
  display:inline-block;
  height:100%;
  width:100%;
  font-weight: 550;
  border:2.5px solid rgb(33, 185, 28);
  cursor:pointer;
  vertical-align:middle;
  text-align:center;
  padding-top:10px;
}
#attendence span{
  font-size: 2.2mm;
  font-weight: 100;
  color:grey;
}

.ion-text{
  margin-bottom: 18px;
  height: 75%;
}
.ion-text>h5{
  font-family:'Open Sans', sans-serif;
  font-size:1.15em;
  font-weight: bolder;
  padding-bottom: 5px;
  margin: 0;
  
}
.ion-text> p{
  font-family: 'Roboto', sans-serif;
   font-size:.7em;
   margin: 0;
   padding:1px 2px 10px 2px;
   font-weight: 600;
  color:rgb(105, 100, 100);
 
}
.link{
   padding:6px 5px;
  font-family: 'Open sans', sans-serif;
  font-size:0.76rem;
    font-weight: 500;
  border-radius: 5px;
  background-color:#3880ff;
  color:rgb(255, 255, 255);
  text-decoration: none;
  cursor: pointer;
  
}
.lnk{
  text-decoration: none;
  cursor: pointer;
  font-size:.9rem;
}


#container{
  width:100%;
}
#progressbar li{
  font-family: 'Open Sans', sans-serif;
  list-style-type: none;
  float:left;
  width: 50%;
 position:relative;
  text-align: center;
  font-size: .9rem;
    font-weight: 600;
 }
  
  .progressbar-spn1::before{
    content:"\25CF";
    font-family: 'Open Sans', sans-serif;
    justify-content:center;
   width :18px;
  height:18px;
   margin: 3.5px auto 0 auto;
    border: 1px solid rgb(133, 129, 129);
    line-height: 1em;
    display:flex;
    border-radius:50%;
   background-color:white;
   
  }
 
  .progressbar-spn1::after{
    content:"";
    position: absolute;
    width:100%;
    height: .5px;
    background-color: rgb(153, 153, 153);
    bottom:9px;
    left:50%;
    z-index: -1;
  }
  #progressbar li.active:first-child{
    color:rgb(48, 132, 242);
    margin:0;
  }
  #progressbar li.active .progressbar-spn1:after{
    border-color:rgb(48, 132, 242);
  }
  

 



.instruct {
  font-family: 'Roboto', sans-serif;
  margin:5px 12px 3px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: .95rem;
  font-weight:bold;
  color: rgba(32, 32, 32, .9);
}
.instruct1{
  font-family: 'Roboto', sans-serif;
  margin:50px 12px 1px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: .95rem;
  font-weight: 800;
  color: rgba(32, 32, 32, .9);
}

#scnd-slides{
  margin-top:6px;
  height:160px;
  z-index: -1;
  
  }
 #scnd-slides .slide{
  margin-left: 12px;
  
 }
#sld1{
  margin-top:70px;
  height:150px;
  margin-left:12px;

}

#card1 #div-cad-butt{
 margin-top: 5px;
 display: flex;
 align-items:center;
 justify-content: center;
 color:#ffffff;
 font-size: 1.5rem;
  border-radius: 5px;
  max-width: 100px;
  cursor:pointer;
  padding: 7px ;
  background:rgba(4, 73, 21, 0.9);
}

 #card1 #div-cad-btn2{
  margin-top:5px;
  display:inline-block;
 
  color:#ffffff;
  font-size: 1.55rem;
  border-radius: 5px;
  background:rgba(9, 49, 158, 0.808);
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
  cursor:pointer;
  vertical-align:middle;
  max-width: 100px;
  padding: 7px 8px;
  text-align: center;

}
.div-cad-button3{
  
  margin-top:5px;
  display:flex;
  justify-content: center;
  align-items: center;
 
  color:#ffffff;
  font-size: 1.2rem;
  border-radius: 5px;
  background:rgba(4, 73, 21, 0.9);
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
  cursor:pointer;
  vertical-align:middle;
  max-width: 100px;
  padding: 8px 6px 8px 6px;
  text-align: center;

}
 .div-cad-button4{
  margin-top:5px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#ffffff;
  font-size: 1.25rem;
  border-radius: 5px;
  background:rgba(9, 49, 158, 0.808);
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
  cursor:pointer;
  vertical-align:middle;
  max-width: 100px;
  padding: 7px 8px;
  text-align: center;
  

}

#card1{
width:100%;
font-family: "Open Sans",sans-serif;
  border:none!important;
  background:white;
  margin-bottom:10px;
 border-radius: 5%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
 box-shadow:  3px 3px rgba(188, 192, 188,0.5);
 padding:5% 3%;
}
#card2{
  margin-bottom:20px;
   border:none!important;
  background:white;
  font-family: 'Open Sans', sans-serif;
    width:100%;
 border-radius: 3%;
 display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
 padding:4% 3%;
 box-shadow:  3px 4px rgba(135, 139, 135, 0.3);
}
#cad1-heder2{
  font-family: 'Open Sans', sans-serif;    
  display:flex;
  flex-direction: column;
 font-size: 20px;
  
}
#cad1-heder3{
  font-family: 'Open Sans', sans-serif;     
  display:flex;
  flex-direction: column;
 font-size: 15px;
  
}
#card1-heder2-1{

  font-size: .9rem;
  padding:3px;
  font-weight: bold;
  color:#383a3e;
}
#card1-heder3-1{
  
  font-size: .8rem;
  padding:3px;
  font-weight:bold;
  color:#383a3e;
}
#card1-heder3-2{
  font-size: .55rem;
  font-weight: 600;
  color:#626368;
}
 #cad1-heder2-2{
 font-size: .65rem;
  font-weight: 600;
  color:#5f5f63;
  padding:5px 0;
}
#btnn{
  font-family: 'Open Sans', sans-serif;
  margin-top: 5px;
  background-color: rgba(117, 164, 226, 0.5);
  border-radius:10px;
  color:rgba(0, 0, 0, 0.911);
  padding:3px 12px ;
  align-items:center;
  display:inline-flex;
  text-align: center;
  justify-content:center;
  margin-right:18px;
  margin-left: 18px;

}
#btnn div.act{
  font-size:.7em;
   font-weight:600;
   padding:0;
   margin-left: 8px;
}
#btnn div {
  padding-top:3px;
}

#btnn2 {
  margin-right:15px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom:5px;
  display:flex;
  font-size:.6rem;
  font-weight: 600;
  align-items: center; 
  justify-content:center;
  color:rgb(150,150,150);
  
}
#btnn2 div.act1{
  padding-left:5px;
  font-weight:600;
  font-size:.5rem;
}
#tch-btnn2 {
  margin-right:20px;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom:5px;
  display:flex;
  font-size:.5rem;
  font-weight: 600;
  align-items: center;
  justify-content:center;
  color:rgb(51, 49, 49);
  
}
#tch-btnn2 ion-icon{
  
  padding:3px;
}
.home-btn{

display:block;
border-radius:50%;
 padding:6px 10px;
  background:rgba(42, 132, 222,1);
}
.ion-toolbar{
  --background:white;
height: 65px;
display:flex;
align-items: center;

}
.tab-bar{
  padding:0;
  
  border:none!important;
  --background:white;
 

--color:rgba(42, 132, 222,1);
align-items: center;
display:flex;
justify-content: space-between;
}
