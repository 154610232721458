.hr{
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-items: center;
}
#di2{
    font-size: 6.3mm;
    font-weight: 900;
     margin-left: 25vw;
     color: rgb(73, 73, 75);
     font-family: 'Tajawal', sans-serif;
}
ion-icon{
    padding-top:3px;
}
 #div-label{
    font-size: 3.9mm;
    font-family: 'Tajawal', sans-serif;
  
}
#input{
    position: relative;
}
.ion-item{
    
    --ion-background-color:transparent !important;
    }
   
    #in-btn{
        margin-top: 3%;
        margin-right: 5%;
        margin-left:65%;
        --padding-end:25px;
        --padding-start:25px;
        
        }

  .ion-div{
      padding:3px 16px 3px 16px;
  }
 