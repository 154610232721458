@media screen and (max-width:400px){
 body #div_2{
   margin-left:27%;
    margin-right: 32%;
  }
 
  body  .progresbar.div{
    width:100%;
  }
 
}
@media screen and (max-width:445px){
  body  .progresbar div>div::before{
    left:5.5%;
  }
}
@media screen and (max-width:430px){
  body  .progresbar div>div::before{
    left:5.8%;
  }
}
@media screen and (max-width:410px){
  body  .progresbar div>div::before{
    left:5.9%;
  }
}
@media screen and (max-width:394px){
  body  .progresbar div>div::before{
    left:5.9%;
  }
}
  @media screen and (max-width:383px){
    body #div_2{
      margin-left:26%;
       margin-right: 32%;
     }
}
@media screen and (max-width:376px){
  body #div_2{
    margin-left:25%;
     margin-right: 32%;
   }
}
@media screen and (max-width:367px){
  body #div_2{
    margin-left:24%;
     margin-right: 32%;
   }
}

.headr{
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content:space-between;
    font-family: 'Open sans', sans-serif;
}
#div_2{
    font-size: 1.1rem;
    font-weight: bold;
    padding:5px 2px;
    margin-left:28%;
    margin-right: 34%;
    color: rgb(73, 73, 75);
}






  .progresbar{
    margin-left: 15px;
    display:flex;
    flex-direction: column;
    height:550px;  
    }
  .progresbar>div{
   
   margin-top: 10px;
   height: 180px;
  
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
   
     
  }
  
  

  .progresbar div>div::before{
    content:"";
    position: absolute ;
    width:.2px;
    height:71%;
    background-color: rgb(102, 101, 101);
    bottom: 7px;
    
    left:5.3%;
    z-index: -1;
  }

 
  .progresbar div span::after{
    content: "\25CF";
      height: 18px;
      width:18px;
      justify-content: center;
     align-items: center;
       border: 1px solid rgb(99, 98, 98);
      display: inline-flex;
      text-align: center;
      margin: 3.5px auto 0 auto;
      border-radius: 60%;
     background-color:white;
    
  }
    
  
    .progresbar div.active{
      color:rgb(48, 132, 242) ;
    
     }
     .progresbar div.active span::after{
      border-color:rgb(48, 132, 242);
    }

    .progresbar div div.div{
      font-weight: 700; 
      font-size: 20px;
      margin-top: 2px;   
      margin-left: 5px;
      font-family: 'Open sans', sans-serif;
      
    }
    .div div.class-time{
      font-size:1rem;
      padding:5px;
    }
  
  .card1{
   width:300px;
    height: 110px;
    padding:  6px;
   background-color:#ffffff;
   border-radius: 8px;
   box-shadow: 3.5px 3.5px rgba(175, 172, 172, 0.5);
   display:flex;
   flex-direction: column;
  }

.card1-headr{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    border-style: none none groove none;
    border-width: thin;
    border-color: rgb(204, 201, 201);
    height:60px;
    
    font-family: 'Open Sans', sans-serif;
  }
  
    .div-card-button{
      border-radius: 5px;
      display:flex;
      justify-content: center;
      align-items: center;
     background:rgba(4, 88, 4,0.9);
      box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
      cursor:pointer;
      vertical-align:middle;
      max-width: 100px;
      padding:  8px ;
      text-align: center;
      font-size:1rem;
    }
    .div-card-button2{
      font-size:1.1rem;
      border-radius: 5px;
      display:flex;
      justify-content: center;
      align-items: center;
     
      color:#ffffff;
      
      background:rgba(16, 67, 143, 0.767);
      box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
      cursor:pointer;
      vertical-align:middle;
      max-width: 100px;
      padding:  8px 10px;
      text-align: center;
      
      
    }
    .div-card-button3{
      font-size:1.1rem;
      border-radius: 5px;
      display:flex;
      justify-content: center;
      align-items: center;
    
      color:#ffffff;
      
      background:rgba(133, 121, 12, 0.89);
      box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
      cursor:pointer;
      vertical-align:middle;
      max-width: 100px;
      padding:  8px 10px ;
      text-align: center
      
    
    }
    #card1-heder2{
       margin-left:8px; 
     display:flex;
      flex-direction: column;
     font-size:20px;
      
      font-family: 'Roboto', sans-serif;
    
  }
  #heder2-1{
      font-size: .95rem;
      font-weight:bolder;
      color:black;
    }
  #card1-heder2-2{
    font-size: .75rem;
    font-weight: 600;
    padding:3px 0;
     color:rgb(104, 100, 100);
  }
  #card1-content{

    display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    
  }
  #card1-content2{
    display:flex;
    margin-top: 8px;
    padding:5px;
  
    align-items: center;
    font-weight: 800;
    justify-content:center;
    color:grey;
  }
  #card1-content2 div.somoy{
    margin-left:8px;
    padding-top:3px;
    font-size:.75rem;
  }
  #btn1{
    background:rgba(117, 164, 226, 0.5);
    margin-top: 8px;
    border-radius: 6px;
    padding:6px;
      width:40%;
      display:flex;
      font-weight: 600;
      align-items: center;
      justify-content:center;
      color:rgb(3, 3, 3);
  }
  #btn1 div.rm{
    font-size:.8rem;
  }
  #btn2{
    background:rgba(117, 164, 226, 0.5);
    margin-top: 8px;
    border-radius: 6px;
    padding:6px;
      width:40%;
      display:flex;
      font-weight: 600;
      align-items: center;
      justify-content:center;
      color:rgb(3, 3, 3);
  }
  #btn2 div.rm{
    font-size:.8rem;
  }
  #btn-jn{
    background:rgba(117, 164, 226, 0.5);
    margin-top: 5px;
    border-radius: 10px;
    
      width:40%;
      display:flex;
    
      align-items: center;
      justify-content:center;
      color:rgb(3, 3, 3);
  }
  #btn-jn div:first-child{
    padding-top:5px;
  }
  #btn-jn div.jn{
    padding-left:5px;
    font-size:.9rem;
    font-weight:600;
  }
  #btn1>div{
    font-size: .65rem;
    color: rgb(95, 93, 93);
    padding: 4px;
    font-weight: bolder;
  }
  #btn2>div{
    font-size: .6rem;
    color: rgb(56, 55, 55);
    padding: 4px;
    font-weight: bolder;
  }
 
    
  .div{
    display:flex;
    flex-direction: column;
    
  }  

  #icon1{
    color:rgba(94, 91, 91,0.8);
    font-size:3.3mm;
    line-height: 2px;
    font-weight:300;
  }
  

 