@media screen and (max-width:410px){
  body .content3-3{
    width :42%;
  }
  body #content3{
    width:37%;
  }
  body #content2{
    width:25%;
  }
body #header .div2{
  margin-right: 25%;
}
}
.ion-button{
display: inline-block;
align-items: center;
background: #3880ff;
border-radius: 10%;
padding:3px 5px 2px 2px;
align-self: center;  
}
.ion-button ion-icon{
  padding-left: 5px;
}
#div-search{
display:grid;
width:95%;
grid-template-columns:90% 10% ;
height:60px;

}

.search-bar{
transform: scaleY(0.85);
  font-family: 'Roboto', sans-serif;
    
  --background:#ffffff;
  --border:none;
  --border-radius:10px 10px 10px 10px;
  --box-shadow: none !important;
    
  --cancel-button-color:rgb(220, 209, 232);
 

}
.search-bar .searchbar-input-container input::placeholder {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  --placeholder-font-weight:300;
}
  
#header{
  font-family: 'Roboto', sans-serif;
    padding: 5px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    
    
} 
#header .div2{
  padding:5px;
  font-size: 1.3rem;
  font-weight: 900;
  color:#505358;
 ;
  margin-right: 27%;
 
   font-family: 'Open sans', sans-serif;
}

.slides{
    width:92%;
    display: flex;
    flex-direction: column;
   margin-left:12px;
    
}
.slides>div{
  padding:8px 10px;
  margin: 15px 0 10px 0 ;
    background: white;
    height: 170px;
    border-radius: 8px;
    box-shadow: 3px 3.5px rgba(165, 168, 165,0.4);
    font-family: 'Tajawal', sans-serif;
      display:flex;
    flex-direction: column;
    
    font-weight:15px;
}
.slides>div:first-child{
  margin-top: 3px  ;
}
#card1-header2{
  margin-left: 10px;
}
#card1-header{
  padding:5px;
    display:flex;
    align-items: center;
    border-width: thin;
    border-style: none none groove none;
    border-color: rgb(213, 213, 219);
    font-family: 'Open Sans', sans-serif;
    height:38%;
  }
 
    .card2-header{
      display:flex;
      align-items: center;
      border-width: thin;
      border-style: none none groove none;
      border-color: rgb(213, 213, 219);
      font-family: 'Open Sans', sans-serif;
      height:38%;
      }
      .card3-header{
        display:flex;
        align-items: center;
        border-width: thin;
        border-style: none none groove none;
        border-color: rgb(213, 213, 219);
        font-family: 'Open Sans', sans-serif;
        height:38%;
      }
    .card1-content{
      padding:2px 4px;
        display:flex;
        flex-direction: column;
        justify-content:center;
        font-family: 'Noto sans', sans-serif;
      }
    
   
    #card1-header2  .card1-header2-1{
      font-family: 'Roboto', sans-serif;
        font-size: 1.1rem;
         font-weight: 900;
        color:#2b2c2e;
    }
    .card1-header2-2{
       margin-top:5px;
        font-size: .75rem;
        font-weight: 800;
        color:#8e8f94;
    }
  #card1-content1{
    font-size: .73rem;
    font-weight: 600;
    color:#848485;
    padding: 4px 0;
     
  }
  #content2{
    display:inline-flex;
    justify-content: space-between;
    align-items: center;
    width:23%;
  }
  #content3{
    padding: 3px 0;
    display:flex;
    justify-content: space-between;
    width:35%;
    align-items: center;
  }
  .content3-3{
    padding: 3px 0;
    display:flex;
    justify-content: space-between;
    width:40%;
    align-items: center;
  }
  #content2 span{
    color:#949494;
    font-size:.7rem;
    font-weight: 800;
    padding:0;
     margin:0;
   }
  #content3 span{
     color:#313131;
    font-size:.7rem;
    font-weight: 600;
    padding:0;
     margin:0;
}
.content3-3 span{
  color:#313131;
    font-size:.7rem;
    font-weight: 600;
    padding:0;
     margin:0;
}
    
    
   #item .div-card-button{
        margin-bottom: 7px;
        font-size:1.3rem;
        margin-top:5px;
        border-radius: 5px;
        display:flex;
        color:#ffffff;
        justify-content: center;
        align-items: center;
        background:rgba(4, 88, 4,0.9);
        box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
        cursor:pointer;
        vertical-align:middle;
        max-width: 100px;
       padding:9px 7px;
        text-align: center;
        
      
      }
   
 
  
      #div-card-button2{
        margin-bottom: 7px;
        font-size:1.3rem;
        margin-top:5px;
        border-radius: 5px;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#ffffff;
        background:rgba(161, 10, 126, 0.9);
        box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
        cursor:pointer;
        vertical-align:middle;
        max-width: 100px;
       padding: 9px 10px;
        text-align: center;
        
      
      }
       
       #div-card-button3{
        margin-bottom: 7px;
        font-size:1.3rem;
        margin-top:5px;
        border-radius: 5px;
        display:flex;
        justify-content: center;
        align-items: center;
        color:#ffffff;
          background:rgba(148, 187, 21, 0.9);
          box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
          cursor:pointer;
          vertical-align:middle;
          max-width: 100px;
        padding: 9px 10px;
          text-align: center;
          
        
        }
            
