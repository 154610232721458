.heder{
   padding:5% 3% 1% 2%;
   font-family: 'Open Sans', sans-serif;
    display:flex;
    justify-content: space-between;
    align-items: center;
    
}

@media screen and (max-width:420px){
  
 body #div-search{
  grid-template-columns:93% 7.5% ;
  }
 
}
@media screen and (max-width:390px){
  
  body #div-search{
   grid-template-columns:92.5% 8% ;
   }
  
 }

#tle{
  padding:5px;
    font-size: 1.25rem;
     text-align:center;
    font-weight: 900;
    color:#505358;
   
}
.ion-button ion-icon{
  padding-left: 5px;
}
#div-search{
 display:grid;
width:95%;

grid-template-columns:93.5% 7% ;
 height:60px;

}

.search-bar{
  transform: scaleY(0.85);
  font-family: 'Roboto', sans-serif;
    
  --background:#ffffff;
  --border:none;
  --border-radius:10px 10px 10px 10px;
  --box-shadow: none !important;
    
  --cancel-button-color:rgb(220, 209, 232);
 

}
.search-bar .searchbar-input-container input::placeholder {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  --placeholder-font-weight:300;
}
 
  
  .grd{
     margin-top:10px;
      display: grid;
      width:100%;
      height:400px;
      grid-auto-rows: minmax(100px,auto);
      grid-template-columns: repeat(2,44%);
      grid-gap:15px 15px;
      margin-left:12px;
      
  }
  .grd>div{
      background-color:#ffffff;
      padding:8px;
      box-shadow:  3px 3px rgba(139, 139, 139, 0.4);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Open Sans', sans-serif;
  }
  
  .div-button{
    margin-bottom: 5px;
    margin-top:6px;
    border-radius: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#ffffff;
    font-size:5mm;
    background:rgba(4, 88, 4,0.9);
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
    
    max-width: 100px;
    padding:7px 5px 7px 5px;
    text-align: center;
  
    
  }
  
  #grid1-2{
      font-size: .9rem;
      font-weight:bolder;
      color: rgb(53, 51, 51);
     font-family: 'Roboto', sans-serif;
  }

  #grid1-3 .tcher{
    padding-left:5px;
    font-weight:600;
    font-size:.55rem;
  }
  #grid1-3{
    margin-right:20px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom:5px;
    display:flex;
    font-size:.5rem;
    font-weight: 600;
    align-items: center;
    justify-content:center;
    color:rgb(51, 49, 49);
  }
  
  .div-button2{
    margin-bottom: 5px;
    margin-top:5px;
    border-radius: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#ffffff;
   font-size:5mm;
    background:#2834a6;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
   
    max-width: 100px;
    padding: 7px 9px ;
    text-align: center;
  
  }
  
  .div-button3{
    margin-bottom: 5px;
    margin-top:6px;
    border-radius: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#ffffff;
    font-size:5mm;
    background:#3dc2ff;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
   
    max-width: 100px;
    padding: 7px 5px;
    text-align: center;
  }
  
  .div-button4{
    margin-bottom: 5px;
    margin-top:6px;
    border-radius: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#ffffff;
    font-size:5mm;
    background:#993687;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
    vertical-align:middle;
    max-width: 100px;
    padding: 7px;
    text-align: center;
  }
  
  .div-button5{
    margin-bottom: 5px;
    margin-top:6px;
    border-radius: 5px;
    display:flex;
    justify-content: center;
    align-items: center;
    color:#ffffff;
    font-size:5mm;
    background:#81cc18;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
    vertical-align:middle;
    max-width: 100px;
    padding: 8px;
    text-align: center;
  
  }