
.calender{
    display: flex;
    flex-direction: column;
    padding:5px 5px 2px 5px;
    align-items: center;
    justify-content: center;
    background-color: white;
    height:75px;
    width:55px;
    border-radius: 8px;
 
    font-size: 15px;
    transition: all 500ms linear;
    color:rgb(48, 132, 242) ;
    font-family: "Open sans", sans-serif;
    font-weight: 800;
}

.calender-slides{
    height: 105px;
    max-width: 450px;
}
 

.active-calender-slide .calender{
    margin-bottom:14px;
    background-color:rgb(48, 132, 242) ;
    color:#ffffff; 
}
.calender-slide:first-child .calender{
    margin-bottom:14px;
    background-color:rgb(48, 132, 242) ;
    color:#ffffff;  
}
.div span::after{
    content:"\2022";
    font-size: 1.3em;
    
}
.div span span::after{
    content:"\2022";
    color:tomato;
    font-size: 1.3em;
}
.div span span span::after{
    content:"\2022";
    color:rgb(14, 3, 63);
   font-size: 1.3em;
}
.spn{
    margin-left:3px;
    color:rgb(75, 72, 72);
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
}

.title{
    padding: 3px;
    color:rgb(48, 132, 242);
    font-size: 1.2rem;
    margin-left: 10px;
    font-weight:bold;
    font-family: 'Roboto', sans-serif;
}